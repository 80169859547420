import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateFormatDDMMYYYY' })
export class dateFormatDDMMYYYY implements PipeTransform {
  transform(value: string): String {
    var d = new Date(value);

    return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()
  }
}
