import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { FacebookServiceService } from '../../core/services/facebook-service.service';
import { LocalstorageService } from '../../core/services/localstorage.service';
import { ToastrService } from '../../core/toastr/toastr.service';

@Component({
  selector: 'app-facebook-login',
  templateUrl: './facebook-login.component.html',
  styleUrls: ['./facebook-login.component.scss']
})
export class FacebookLoginComponent implements OnInit {

  public returnUrl: string = '';
 
  constructor(private facebookService: FacebookServiceService,
     private toast: ToastrService,
    private router: Router, private authService: AuthService, private local_storage: LocalstorageService) { }

  ngOnInit(): void {
    
  }
  logInUserViaFaceBook() {
    this.facebookService.login().subscribe((res: any) => {
      this.authService.getLoggedIn(res).subscribe((response: any) => {
        if (response.success) {
          let token = response.data.token
          delete response.data.token
          this.local_storage.saveToken(token, response.data)

          this.toast.showSuccess(response.message);
          if( !(response.data.Address && response.data.Address.postal_code)){
            return  this.router.navigateByUrl('/user/profile');
           }
          if (this.returnUrl) {
            return this.router.navigateByUrl(this.returnUrl)
          }
          if(response.data.roles_id == 2){
            this.router.navigateByUrl("/user")
          }else if(response.data.roles_id == 1 || response.data.roles_id == 5 ){
            this.router.navigateByUrl("/admin");
          }
          // return response.data.roles_id == 2 ? this.router.navigateByUrl('/user') : this.router.navigateByUrl('/admin')

        }
        else {
          return this.toast.showError(response.message)
        }
      })
    })
  }

  // logInUserViaInstagram() {
  //   this.facebookService.instaLogin()
  //   // .subscribe((res: any) => {
    
  //   // })
  // }
}
