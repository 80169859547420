<div class="TitleBox">

    <!-- <img src="assets/images/logo-round.png" /> -->
    <!-- <span class="TitleBox__Text Header_Title"><img src="assets/images/logo-round.png" class="header-logo" alt="logo" /> &nbsp; {{headerTiles}}</span>  -->
    <span class="TitleBox__Text Header_Title"><img [src]="logoUrl" class="header-logo" alt="logo" /> &nbsp; {{headerTitle}}</span> 

    <!-- <span class="TitleBox__Text">Manage Clients</span> -->
</div>
<div class="ToolBarBox">
    <div id="leftButtonBank" class="ToolBarButtonBank reverse">
        <!-- <div  class="ToolBarButton reverse" (click)="location.back()" title="Back"> -->
        <div  class="ToolBarButton reverse" (click)="this.location.back()" title="Back">
            <img src="assets/img/icons/back.svg" alt="Back" class="ToolBarButton__Icon">
        </div>
        <div id="cmdBack" class="ToolBarButton reverse" (click)="this.navigateToHome()" title="Back">
            <img src="assets/img/icons/earth.svg" alt="Back" class="ToolBarButton__Icon">
        </div>
        <div id="cmdRefresh" class="ToolBarButton reverse" (click)="this.refresh()" title="Refresh">
            <img src="assets/img/icons/reload.svg" alt="Refresh" class="ToolBarButton__Icon">
        </div>
    </div>

    <div style="flex-grow: 2;"> &nbsp; </div>
    <div id="navButtonBank" class="ToolBarButtonBank">
        <div class="ToolBarButton" *ngIf="activeURL.indexOf('order-entry')>-1 && (activeURL.indexOf('view-staged')>-1 ||activeURL.indexOf('edit')>-1)" (click)="router.navigateByUrl('/admin/order-entry')" title="Order Entry">
            <img src="assets/img/icons/point-of-sale.svg" alt="Order Entry" class="ToolBarButton__Icon">
        </div>
        <div id="cmdViewStaged" class="ToolBarButton" *ngIf="activeURL.indexOf('order-entry')>-1 && !(activeURL.indexOf('view-staged')>-1) " (click)="router.navigateByUrl('/admin/order-entry/view-staged')" title="View Staged">
            <img src="assets/img/icons/clipboard.svg" alt="View Staged" class="ToolBarButton__Icon">
        </div>
        <h5 class="header-element TitleBox__Text ml-3" style="cursor:pointer;margin-left: 3px;">{{username}} &nbsp; | &nbsp; </h5>
        <h5 class="header-element TitleBox__Text" style="cursor:pointer;" (click)="logout()"> Logout</h5>
    </div>
</div>