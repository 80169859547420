import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonPagesRoutingModule } from './common-pages-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FacebookServiceService } from 'src/app/core/services/facebook-service.service';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { PdfReceiptComponent } from '../admin/order-entry/pdf-receipt/pdf-receipt.component';


@NgModule({
  declarations: [HeaderComponent,FooterComponent, AdminHeaderComponent,PdfReceiptComponent],
  imports: [
    CommonModule,
    CommonPagesRoutingModule
  ],
  exports:[HeaderComponent,FooterComponent,AdminHeaderComponent,PdfReceiptComponent],
  providers:[FacebookServiceService,AdminHeaderComponent]
})
export class CommonPagesModule { }
