<div id="htmlData" style="
    width: 2.5in;
    max-height: 400px;
    background-color: white;
    color: black;
    font-size: 19px;
    margin: 0 auto;
  ">
    <div style="
      width: 100%;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
      text-align: center;
      padding: 8px;
    ">
        <div style="border-bottom: 1px solid black">
            <b>{{ this.livestream_name }}</b>
            <br />
            <b style="font-weight: 500;">{{ this.clientName }}</b>
        </div>

        <div style="width: 100%; padding: 6px; display: flex; flex-direction: column">
            <h5 style="text-align: center;font-size: 16px;">{{ this.product_name }}</h5>
            <div style="width: 100%; /* display: flex; */">
                <p style="float: left"><b>Price</b></p>
                <p style="float: right"><b>Quantity</b></p>
            </div>
            <div style="width: 100%; font-size: 20px;">
                <p style="float: left">$ {{ this.price }}</p>
                <p style="float: right">{{ this.quantity }}</p>
            </div>
            <div style="width: 100%; font-size: 20px;text-align: center;">
                Total = $ {{ getTotal() }}
            </div>
        </div>

        <div style="border-bottom: 1px solid black"> 
            <!-- <b>Host : {{ !this.host ? "No Host" : this.host }}</b><br>
            <b>platform : {{ this.platform }}</b>  -->
            <p style="font-family: monospace;">{{this.platform}}{{this.host}}</p>
            
        </div>
        <div style="width: 100%; /* display: flex; */ margin-top: 10px;font-size: 16px;" [innerHTML]="breackLineForTime()">
        </div>
    </div>
</div>