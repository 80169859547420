import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './core/interceptor/request.interceptor';
import { appInitializer } from './core/intilizer/fb-initlizer';
import { FacebookServiceService } from './core/services/facebook-service.service';
import { LocalstorageService } from './core/services/localstorage.service';
import { SharedModulesModule } from './core/shared-modules.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPayPalModule } from 'ngx-paypal';
import { CommonPagesModule } from './layout/common/common-pages.module';
import { RegisterComponent } from './register/register.component';
import { FormsModule } from '@angular/forms';
import { LoginModule } from './login/login.module';
import { DomainSettingService } from './core/services/domain-setting.service';
import { initializeDomain } from './core/intilizer/domain-initlizer';
import { GemmonkeyComponent } from './gemmonkey/gemmonkey.component';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    GemmonkeyComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModulesModule,
    NgxPayPalModule,
    CommonPagesModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    LoginModule,
    // NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [FacebookServiceService] },
    //{ provide: APP_INITIALIZER, useFactory: chatInitializer, multi: true, deps: [] },
    DomainSettingService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeDomain,
      deps: [DomainSettingService, Title],
      multi: true
    },
    LocalstorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
