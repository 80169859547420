import { Title } from "@angular/platform-browser";
import { DomainSettingService } from "../services/domain-setting.service";
import { environment } from "src/environments/environment";

function prepareDomainBasedOnEnv(){
  let project = environment.project
  
  if(project == 'thetucsongemshow'){
    return 'thetucsongemshow.live'
  }else if(project == 'gemmonkey'){
    return 'gemmonkey.com'
  }else if(project == 'sunwest'){
    return 'gemmonkey.com/sunwest'
  }else if(project == 'staging'){
    return 'staging.thetucsongemshow.live'
  }
  
  else{
    let domain = window.location.host; // Get the current domain name
    
    if(window.location.pathname.includes('sunwest')){
      domain = 'gemmonkey.com/sunwest'

    }
    return domain
  }
}

export function initializeDomain(domainSettingsService: DomainSettingService, title: Title) {
  return () => {
    let domain = prepareDomainBasedOnEnv()

    
    const settings = domainSettingsService.getSettings(domain);

    // Apply website title
    title.setTitle(settings.title);
    //  change fevicon icon
    domainSettingsService.setAppFavicon(settings.feviconIcon)

    // Update the logo
    domainSettingsService.updateLogo(settings.logo);

    // Update website header titile 
    domainSettingsService.updateHeaderTitle(settings.header_title);

    // Apply body color
    const body = document.body;
    body.classList.add(settings.bodyColor);

    //  update terms of service and privacy policy
    domainSettingsService.updateTerms_Policy(settings.terms_service, settings.privacy_policy);

    //  update background logo
    domainSettingsService.updateBackground_logo(settings.background_logo);

    //  get login portal url for gemmonkey and staging landing page
    domainSettingsService.updatePortalLoginUrl(settings.loginPortal);
  };
}