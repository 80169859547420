import { UserService } from './services/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { AuthInterceptorService } from './interceptor/request.interceptor';
import { LivestreamService } from './services/livestream.service';
import { DayName } from './pipes/dayFromDatePipe';
import { DateFormat } from './pipes/dateFormate';
import { ProductService } from './services/product.service';
import { ToastrComponent } from './toastr/toastr.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { AlertComponent } from './alert/alert.component';
import { SafeHtmlPipe } from './pipes/safeHtml';
import { dateFormatDDMMYYYY } from './pipes/dateFormatDDMMYYYY';
import { SpeechRecognitionDirective } from './speechRecognition/speech-recognition.directive';
import { CheckAccessService } from './services/check-access.service';



@NgModule({
  declarations: [
    DayName, DateFormat, ToastrComponent, LoaderComponent, AlertComponent, SafeHtmlPipe, dateFormatDDMMYYYY, SpeechRecognitionDirective
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    AuthService, LivestreamService, ProductService,
    CheckAccessService
  ],
  exports: [DayName, DateFormat, ToastrComponent, LoaderComponent, AlertComponent, SafeHtmlPipe, dateFormatDDMMYYYY, SpeechRecognitionDirective]
})
export class SharedModulesModule { }
