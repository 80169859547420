import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public observer !: Observer<any>;
  public toastrObserver: Observable<any> = new Observable();
  constructor() {
    this.toastrObserver = new Observable((obseber) => {
      this.observer = obseber
    })
  }
  show(): void {
    this.observer.next(true)
  }
  public getLoader() {
    return this.toastrObserver
  }
  hide() {
    this.observer.next(false)

  }
}
