import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayname' })
export class DayName implements PipeTransform {
  transform(value: string): String {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(value);
    var day = days[d.getDay()];
    return day
  }
}


