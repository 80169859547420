import {  Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface DomainSettings {
  title: string;
  header_title: string;
  bodyColor: string;
  logo: string;
  terms_service: string;
  privacy_policy: string;
  background_logo: string;
  feviconIcon: string;
  loginPortal?:string;
}

@Injectable({
  providedIn: 'root'
})
export class DomainSettingService {

  // constructor(@Inject(DOCUMENT) private _document: HTMLDocument) { }
  constructor() { }

  private logoSubject = new BehaviorSubject<string>('logo-round.png');
  private headerTitleSubject = new BehaviorSubject<string>('The Tucson Gem Show');
  private terms_serviceSubject = new BehaviorSubject<string>('');
  private background_logoSubject = new BehaviorSubject<string>('');
  private loginPortalURL = new BehaviorSubject<string>('');






  private domainSettingsMap: { [domain: string]: DomainSettings } = {
   
    'staging.thetucsongemshow.live': {
      title: 'TheTucsonGemShow.Live',
      header_title: 'The Tucson Gem Show',
      bodyColor: 'TheTucson-GemShow',
      logo: 'https://thetucsongemshow.live/assets/images/logo-round.png',
      terms_service: 'terms-of-service.txt',
      privacy_policy: 'tucsongemshow-privacy-poicy.txt',
      background_logo: 'assets/images/The-Tucson-Gem-Show-Logo-Final-grayscale.png',
      feviconIcon: "https://thetucsongemshow.live/assets/images/logo-round.png"
    },
    'thetucsongemshow.live': {
      title: 'TheTucsonGemShow.Live',
      header_title: 'The Tucson Gem Show',
      bodyColor: 'TheTucson-GemShow',
      logo: 'https://thetucsongemshow.live/assets/images/logo-round.png',
      terms_service: 'terms-of-service.txt',
      privacy_policy: 'tucsongemshow-privacy-poicy.txt',
      background_logo: 'assets/images/The-Tucson-Gem-Show-Logo-Final-grayscale.png',
      feviconIcon: "https://thetucsongemshow.live/assets/images/logo-round.png"
    },
    'gemmonkey.com/sunwest': {

// For Sunwest silver  
      title: 'Sunwest Silver Co Inc',
      header_title: 'Sunwest Silver Co Inc',
      bodyColor: 'Sunwest-Silver',
      logo: 'https://gemmonkey.com/sunwest/assets/images/SWS-LOGO.png',
      terms_service: 'gemmonkey-terms-of-service.txt',
      privacy_policy: 'sunwest-privacy-policy.txt',
      // privacy_policy: 'PrivacyPolicy_sunwest.docx',

      background_logo: 'assets/domain-logo/SWS-LOGO.png',
      feviconIcon: "https://gemmonkey.com/sunwest/assets/images/SWS-LOGO.png",
      loginPortal:"https://gemmonkey.com/sunwest/index2.html"




    },
    'gemmonkey.com': {
      title: 'Gem Monkey Livestream POS',
      header_title: 'Gem Monkey Livestream POS',
      bodyColor: 'Gem-Monkey',
      logo: 'https://gemmonkey.com/assets/images/GEMMONKEY.svg',
      terms_service: 'gemmonkey-terms-of-service.txt',
      // privacy_policy: 'gemmonkey-privacy-policy.txt',
      privacy_policy: 'gemMonkeyPolicy.txt',
      background_logo: "assets/images/fx-gs.webp",
      feviconIcon: "https://img1.wsimg.com/isteam/ip/609e17f2-d182-4baf-899c-529e89836554/GEM-MONKEY-LOGO-WHITE%20(1)-4c707ca.svg/:/cr=t:0%25,l:0.15%25,w:99.7%25,h:100%25/rs=w:719,h:395",
      loginPortal:"https://gemmonkey.com/index2.html"

    },

    // domain for "SUNWEST-SILVER Client"
    'localhost:4200': {
      title: 'Sunwest Silver Co Inc',
      header_title: 'Sunwest Silver Co Inc',
      bodyColor: 'Sunwest-Silver',
      logo: 'https://localhost:4200/assets/images/SWS-LOGO.png',
      terms_service: 'gemmonkey-terms-of-service.txt',
      // privacy_policy: 'gemmonkey-privacy-policy.txt',
      privacy_policy: 'sunwest-privacy-policy.txt',
      background_logo: 'assets/domain-logo/SWS-LOGO.png',
      feviconIcon: "https://localhost:4200/assets/images/SWS-LOGO.png",
      loginPortal:"https://staging.thetucsongemshow.live/index2.html"



    },

    // Add more domain settings as needed
  };

  getSettings(domain: string): DomainSettings {
    
    return this.domainSettingsMap[domain] || {
      title: 'TheTucsonGemShow.Live',
      header_title: 'The Tucson Gem Show',
      bodyColor: 'TheTucson-GemShow',
      logo: 'https://thetucsongemshow.live/assets/images/logo-round.png',
      terms_service: 'terms-of-service.txt',
      privacy_policy: 'tucsongemshow-privacy-poicy.txt',
      background_logo: 'assets/images/The-Tucson-Gem-Show-Logo-Final-grayscale.png',
      feviconIcon: "https://thetucsongemshow.live/assets/images/logo-round.png"


    };
  }

  updateLogo(logo: string) {
    this.logoSubject.next(logo);
  }
  getLogoObservable() {
    return this.logoSubject.asObservable();
  }
  updateHeaderTitle(headerTitle: string) {
    this.headerTitleSubject.next(headerTitle);
  }
  getHeaderTitleObservable() {
    return this.headerTitleSubject.asObservable();
  }

  updateTerms_Policy(terms: string, privacy: string) {
    const data: any = {
      terms_service: `/assets/${terms}`, privacy_policy: `/assets/${privacy}`
    }
    this.terms_serviceSubject.next(data);
  }
  getTerms_Policy() {
    return this.terms_serviceSubject.asObservable();
  }

  updateBackground_logo(logoUrl: any) {

    this.background_logoSubject.next(logoUrl);
  }
  getBackground_logo() {
    return this.background_logoSubject.asObservable();
  }

  //  fevicon change
  setAppFavicon(url: any) {
   
    const linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    linkElement.href = url;
  }
  updatePortalLoginUrl(url:any){
    this.loginPortalURL.next(url);
  }
  getPortalLoginUrl(){
    return this.loginPortalURL.asObservable();
  }


}
