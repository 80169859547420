import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class CheckAccessService {
  rolesList: any[] = [];
  roleHirarcy: string[] = ["client", "vendor", "employee", "admin", "owner"];
  constructor(private http: HttpClient, private authService: AuthService) {
    this.getRolesList();
  }
  getRolesList() {
    this.http
      .get(environment.serverUrl + "/user/get-all-roles")
      .subscribe((res: any) => {
        if (res.success) {
          this.rolesList = res.data;
        }
      });
  }
  getRolesById(role_id: number) {
    return this.rolesList.find((ele) => ele.id == role_id)?.name;
  }
  getHiracyIndex(role: string) {
    return this.roleHirarcy.findIndex((ele) => ele == role);
  }

  accessUserList(user_role_id: number) {
    let loggedInUserRoleId = this.authService.getUserType();

    let accessingUser = this.getRolesById(user_role_id);
    

    if (loggedInUserRoleId === 5) {
      return true;
    } else if (
      loggedInUserRoleId === 1 &&
      (this.getHiracyIndex(accessingUser) == 0 ||
        this.getHiracyIndex(accessingUser) == 1 ||
        this.getHiracyIndex(accessingUser) == 2)
    ) {
      
      return true;
    } else if(loggedInUserRoleId === 3){
return false
    }else{

      return false
    }

  }
}
