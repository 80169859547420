import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public show: boolean = false;
  public stack: number[] = []
  constructor(private loader: LoaderService) {

  }

  ngOnInit(): void {
    this.loader.getLoader().subscribe((res: any) => {
      if (window.location.href.indexOf('/admin/livestream') > -1) {
        return;
      }
      res ? this.stack.push(0) : this.stack.pop();
      
            this.show = res
    })
  }

}
