<div class="container-fluid">
  <div class="row justify-content-center align-items-center vh-100">
    <div class="col-md-6 col-lg-4">
      <div class="reset-password-form">
        <div class="">
          <div class="card-header">
            <h3>Forget Password</h3>
          </div>
          <div class="card-body">
            <form  #f="ngForm"  (ngSubmit)="submitForm(f)"> 
              <div class="form-group">
                <label for="email">Email Address</label>
                <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" [(ngModel)]="email">
              </div>
              <p class="form-footer mt-24 t-14">
                We’ll send a you reset password link to this email if it matches an existing  account.
              </p>
              <div class="bottom-button">
  
                <button type="submit" class="btn btn-primary btn-block" style=" width: 100%;" >Submit</button>
              </div>
              <div class="bottom-button">
                <a href="login" id="back-button-password-reset" class="back_button" aria-label="Back" role="button">
                  Back
                </a>  
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
