import { environment } from '../../../environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError, map, Subject } from "rxjs";
import { AuthService } from "../services/auth.service";
import { LoaderService } from '../loader/loader.service';
import { Router } from '@angular/router';
import { ToastrService } from '../toastr/toastr.service';

@Injectable({
  'providedIn': "root"
})
export class AuthInterceptorService implements HttpInterceptor {
  loaderURL = ''
  constructor(private authService: AuthService, private loader: LoaderService, private router: Router, private toastr: ToastrService) { }
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  //   const token = this.authService.getAuthToken();

  //   if (request.url.indexOf(environment.serverUrl) > -1 || (request.url != `${environment.serverUrl}/livestream?limit=100&page=1&sort=DESC&filter=All` && request.url.indexOf('get-all-roles')==-1)) {
  //     this.loader.show();
  //     let headers = new HttpHeaders({ 'UserToken': `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' })
  //     request = request.clone({ headers });
  //   }

  //   return next.handle(request).pipe(
  //     catchError((err: any) => {
  //       this.loader.hide()
  //       if (err instanceof HttpErrorResponse) {

  //         if (err.status === 401 || err.status === 403) {
  //          this.router.navigateByUrl('/login')
  //         }
  //       }
  //       return throwError(err);
  //     })
  //   ).pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
  //     if (evt instanceof HttpResponse) {
  //       this.loader.hide()
  //       this.loaderURL = ''
  //     }
  //     return evt;
  //   }));
  // }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getAuthToken();

    // Only intercept requests that match the conditions
    // if (request.url.indexOf(environment.serverUrl) > -1 && !(request.url.includes('livestream?limit=100&page=1&sort=DESC&filter=All') || request.url.includes('get-all-roles'))) {

    if (request.url.indexOf(environment.serverUrl) > -1 && !( request.url.includes('get-all-roles'))) {
      this.loader.show();
      const headers = request.headers.set('UserToken', `Bearer ${token}`).set('Access-Control-Allow-Origin', '*');
      request = request.clone({ headers });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loader.hide();
        }
        return event;
      }),
      catchError((error: any) => {
        this.loader.hide();
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          this.router.navigateByUrl('/login');
        }
        return throwError(error);
      })
    );
  }
}
