<div class="TitleBox">
    
    <span class="TitleBox__Text Header_Title"><img [src]="logoUrl" class="header-logo" id="headerLogo" alt="logo" /> &nbsp;{{headerTitle}}</span> 
    <!-- <span class="TitleBox__Text">Live</span> -->
</div>
<div class="ToolBarBox">

    <div id="leftButtonBank" class="ToolBarButtonBank reverse">

    <!-- <div id="leftButtonBank" class="ToolBarButtonBank reverse">

        <div *ngIf="this.activeURL&& this.activeURL.length>1" class="ToolBarButton reverse" (click)="location.back()" title="Back">
            <img src="assets/img/icons/back.svg" alt="Back" class="ToolBarButton__Icon">
        </div>
        <div *ngIf="userData.roles_id==1 || userData.roles_id==3" class="ToolBarButton reverse"  [routerLink]="is_ZIPCODE_Filled ? ['/admin'] : []"  title="Admin">
            <img src="assets/img/icons/key.svg" alt="Admin" class="ToolBarButton__Icon">
        </div>

        <div class="ToolBarButton reverse"  [routerLink]="is_ZIPCODE_Filled ? ['/user'] : []" title="Home">
            <img src="assets/img/icons/earth.svg" alt="Home" class="ToolBarButton__Icon">
        </div>
    </div> -->

        <div *ngIf="this.activeURL&& this.activeURL.length>1" class="ToolBarButton reverse" (click)="location.back()" title="Back">
            <img src="assets/img/icons/back.svg" alt="Back" class="ToolBarButton__Icon">
        </div>
        <div *ngIf="userData.roles_id==1 || userData.roles_id==3 || userData.roles_id==5" class="ToolBarButton reverse" (click)="router.navigateByUrl('/admin')" title="Admin">
            <img src="assets/img/icons/key.svg" alt="Admin" class="ToolBarButton__Icon">
        </div>

        <div class="ToolBarButton reverse" (click)="router.navigateByUrl('/user')" title="Home">
            <img src="assets/img/icons/earth.svg" alt="Home" class="ToolBarButton__Icon">
        </div>
    </div>

    <div style="flex-grow: 2;"> &nbsp; </div>
    <div id="navButtonBank" class="ToolBarButtonBank">
        <h5 class="header-element TitleBox__Text" style="cursor:pointer;">{{username}} &nbsp; | &nbsp;</h5>
        <h5 class="header-element TitleBox__Text" style="cursor:pointer;" (click)="logout()"> Logout</h5>
    </div>
</div>