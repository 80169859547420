import { LocalstorageService } from "../core/services/localstorage.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FacebookServiceService } from "../core/services/facebook-service.service";
import { AuthService } from "../core/services/auth.service";
import { NgForm } from "@angular/forms";
import { ToastrService } from "../core/toastr/toastr.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { DomainSettingService } from "../core/services/domain-setting.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isSubmitted: boolean = false;
  @ViewChild("content", { static: true }) content!: NgbModalRef;
  // title: string = environment.projectTitle
  title: any;
  public showAdminLogin: boolean = true;
  public returnUrl: string = "";
  public allowed: boolean = false;
  contiueData: any;
  public logoUrl: any;
  logoSubscription: any;

  constructor(
    private facebookService: FacebookServiceService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private local_storage: LocalstorageService,
    private domainSettingsService: DomainSettingService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    if (window.history.state.returnUrl) {
      let dataUrl = window.history.state.returnUrl;
      let replaceCode = dataUrl.replace("/admin?code=", "");
      const finalCode = replaceCode.replace("&state=257B#_", "");

      this.exchangeCodeForToken(finalCode);
    }

    this.logoSubscription = this.domainSettingsService
      .getLogoObservable()
      .subscribe((logo) => {
        // this.logoUrl = `/assets/images/${logo}`;
        this.logoUrl = `${logo}`;
      });

    this.logoSubscription = this.domainSettingsService
      .getHeaderTitleObservable()
      .subscribe((title) => {
        this.title = title;
      });
    this.returnUrl = history.state.returnUrl || "";
    this.checkAlloweRegister();
    this.setUpConitnuePopup();

    setTimeout(() => {
      // this.logInAdmin()
    }, 5000);
  }

  setUpConitnuePopup() {
    this.facebookService.continueToLoginPopUp
      .asObservable()
      .subscribe((data: any) => {
        if (data.email && data.id) {
          this.contiueData = data;
          this.modalService.open(this.content).result.then(
            (data) => {},
            (reason: any) => {}
          );
        }
      });
  }
  logInUserViaFaceBook() {
    this.facebookService.login().subscribe((res: any) => {
      this.authService.getLoggedIn(res).subscribe((response: any) => {
        if (response.success) {
          let token = response.data.token;
          delete response.data.token;
          this.local_storage.saveToken(token, response.data);

          this.toast.showSuccess(response.message);
          if (this.returnUrl) {
            return this.router.navigateByUrl(this.returnUrl);
          }
          if (response.data.roles_id == 2) {
            this.router.navigateByUrl("/user");
          } else if (
            response.data.roles_id == 1 ||
            response.data.roles_id == 5
          ) {
            this.router.navigateByUrl("/admin");
          }

          // return response.data.roles_id == 2
          //   ? this.router.navigateByUrl("/user")
          //   : this.router.navigateByUrl("/admin");
        } else {
          return this.toast.showError(response.message);
        }
      });
    });
  }
  LoginUser() {
    return this.router.navigate(["/login-user"]);
  }
  LoggedInViaPopUp(res: any) {
    // let res={
    //   email: "vikas.p@cisinlabs.com",
    //   first_name: "Vikas",
    //   id: "5590865027643496",
    //   last_name: "P"
    // }
    this.authService.getLoggedIn(res).subscribe((response: any) => {
      if (response.success) {
        let token = response.data.token;
        delete response.data.token;
        this.local_storage.saveToken(token, response.data);

        this.toast.showSuccess(response.message);
        if (this.returnUrl) {
          return this.router.navigateByUrl(this.returnUrl);
        }
        if (response.data.roles_id == 2) {
          this.router.navigateByUrl("/user");
        } else if (response.data.roles_id == 1 || response.data.roles_id == 5) {
          this.router.navigateByUrl("/admin");
        }

        // return response.data.roles_id == 2
        //   ? this.router.navigateByUrl("/user")
        //   : this.router.navigateByUrl("/admin");
      } else {
        return this.toast.showError(response.message);
      }
    });
  }
  navigateToAdmin() {
    //this.showAdminLogin = true;
    this.router.navigateByUrl("admin-login");
  }
  checkAlloweRegister() {
    this.authService.featureAllow().subscribe((res: any) => {
      this.allowed = res.data.register;
    });
  }

  //  radmin-login to login

  logInAdmin(f: NgForm) {
    this.isSubmitted = true;
    if (f.valid) {
      const data = {
        email: f.value.email,
        password: f.value.password,
      };
      this.authService.adminLogin(data).subscribe((response: any) => {
        if (response.success) {
          let token = response.data.token;
          delete response.data.token;
          this.local_storage.saveToken(token, response.data);

          this.toast.showSuccess("Login Successfully !!", response.message);

          if (!(response.data.Address && response.data.Address.postal_code)) {
            return this.router.navigateByUrl("/user/profile");
          } else if (response.data.roles_id == 2) {
            return this.router.navigateByUrl("/user");
          } else if (
            response.data.roles_id == 1 ||
            response.data.roles_id == 5 || response.data.roles_id == 3
          ) {
            return this.router.navigateByUrl("/admin");
          }
        } else {
          this.toast.showWarn("WARNING", response.message);
          return this.router.navigateByUrl("/login-user");
          //return this.toast.showError("Login Error !!",response.message)
        }
        return ;
      });
    }
  }

  goto() {
    if (this.allowed) {
      return this.router.navigate(["/register"]);
    } else {
      return 0;
    }
  }

  // instagram login

  logInUserViaInstagram() {
    const clientId = "710939001226872";
    const clientSecret = "3efd23c2df650317994ead0d28b6a1f8";
    const redirectUri = "https://staging.thetucsongemshow.live/admin";
    const grantType = "authorization_code";
    const scope = "user_profile,user_media";
    const state = "257B"; // Optional

    // ...............................Harish sir test account................................
    // const clientId = "1347310522649728";
    // const clientSecret = "b8216cd4b46ada8df6303994e651e0c0";
    // const redirectUri = "https://staging.thetucsongemshow.live/";
    // const grantType = "authorization_code";

    // const scope = "user_profile,user_media";
    // const state = "257B"; // Optional
    // ...............................Harish sir test account END ................................

    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&state=${state}`;

    window.location.href = authUrl;
  }

  exchangeCodeForToken(code: string) {
    this.authService.getInstagram_AccessToken(code).subscribe((res: any) => {
      if (res.data.id && res.data.username) {
        this.authService.instagramLogin(res.data).subscribe((res: any) => {
          if (res.success) {
            let token = res.data.token;
            delete res.data.token;
            this.local_storage.saveToken(token, res.data);

            this.toast.showSuccess(res.message);
            if (
              !(res.data.email && res.data.first_name && res.data.paypal_email)
            ) {
              return this.router.navigateByUrl("/user/profile");
            } else {
              if (this.returnUrl) {
                return this.router.navigateByUrl(this.returnUrl);
              }

              return res.data.roles_id == 2
                ? this.router.navigateByUrl("/user")
                : this.router.navigateByUrl("/admin");
            }
          } else {
            return this.toast.showError(res.message);
          }
        });
      }
    });
  }
}
