import { LocalstorageService } from './localstorage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of, EMPTY, Subject } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from '../toastr/toastr.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FacebookServiceService {
  continueToLoginPopUp: Subject<any> = new Subject()
  loggingAttempt: number = 0;
  constructor(private http: HttpClient, private localStorage: LocalstorageService, private toastr: ToastrService,private route: ActivatedRoute,) {
  }
  login() {
    if (this.loggingAttempt >= 3) {
      this.toastr.showWarn('Performing login many times')

    }
    if (this.loggingAttempt >= 5) {

      this.toastr.showError('Disabled', 'Login Not Allowed')

      return new Subject().asObservable()
    }
    return this.facebookLogin()
      .pipe(concatMap(accessToken => this.apiAuthenticate(accessToken)))
  }
  facebookLogin() {

    return from(new Promise<fb.StatusResponse>(resolve => {
      this.loggingAttempt++;
      FB.login(resolve, { scope: 'public_profile,email' })
    }))
      .pipe(concatMap(({ authResponse, status }: any) => {
        if (status != 'connected' || !authResponse) { this.toastr.showError('Error', String(status).toUpperCase()); return EMPTY };
        return of(authResponse.accessToken);
      }));
  }
  apiAuthenticate(accessToken: string) {
    
    return this.http.get<any>(`https://graph.facebook.com/v14.0/me?fields=email,first_name,last_name,gender,hometown&access_token=${accessToken}`)
      .pipe(map(account => {
        if (!account.authResponse) {
          this.localStorage.saveAccessToken(JSON.stringify(account))
        }
        return account;
      }));
  }

  logout() {
    let _this = this
    return new Promise((resolve: any) => {

      FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          FB.logout(function (response) {

            _this.localStorage.removeAccount()
            return resolve({})
          });
        }
      });

    })
  }
  showContinueToLogin(data: any) {
    this.continueToLoginPopUp.next(data)
  }

 


  

}
