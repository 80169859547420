import { LocalstorageService } from './localstorage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public url = environment.serverUrl + '/auth';
  public checkZIPCODE = new BehaviorSubject<boolean>(false);
  ZIPCODE$ = this.checkZIPCODE.asObservable();
  constructor(private http: HttpClient, private localstroageService: LocalstorageService) { }
  getLoggedIn(data: any) {
    return this.http.post(this.url + '/getLoggedIn', data)
  }
  getAuthToken() {
    return localStorage.getItem('TOKEN')
  }
  adminLogin(data: any) {

    return this.http.post(this.url + '/user-login', data)
  }
  getUserType() {
    let data = JSON.parse(localStorage.getItem('USER_DATA') || '{}')
    if (data && data.roles_id) {
      return data.roles_id
    }
    return null
  }
  isLoggedIn() {
    return !!localStorage.getItem('TOKEN')
  }
  logout() {
    localStorage.clear()

    sessionStorage.clear()

    return;
  }

  registration(data: any) {
    return this.http.post(this.url + '/register', data)
  }

  allowRegister(data: any) {
    return this.http.post(this.url + '/allow-register', data)
  }
  featureAllow() {
    return this.http.get(this.url + '/get-allowed')
  }
  scheduleForm(data:any){
    return this.http.post(this.url+'/schedule-form',data)
  }

  // check_ZIPCODE_EXIST(check:any){
  //   this.checkZIPCODE.next(check);
  // }


  getInstagram_AccessToken(code:any){
    
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  // return this.http.post('https://api.instagram.com/oauth/access_token/', data);
  return this.http.post(this.url+'/instagram-access-token',{code:code})

  }

  getUser_InstagramDetails(user_id:any,access_token:any){
    return this.http.get(`https://graph.instagram.com/${user_id}?fields=id,username&access_token=${access_token}`)
  }

  instagramLogin(data:any){
    return this.http.post(this.url+'/Instagram-LoggedIn',data)

  }

  forgetPassword(email:any){
    return this.http.post(this.url+'/forget-password',email)
  }

  restPassword(data:any){
    return this.http.post(this.url+'/reset-password',data)
  }

}
