import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LivestreamService {

  livestreamURL = environment.serverUrl + '/livestream'
  constructor(private http: HttpClient) { }
  createLiveStream(data: any) {
    return this.http.post(this.livestreamURL, data)
  }

  // Create livestream for show speacials
  createShowSpecialLiveStream(data: any) {
    return this.http.post(this.livestreamURL+'/show-specials-livestream', data)
  }
  getAllLiveStreamByMonthYear(filter = 'All', year:any, month:any ,sort: string = 'DESC', limit: number = 100, page: number = 1) {
    return this.http.get(this.livestreamURL + `?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}&year=${year}&month=${month}`);
  }
  getAllLiveStream(filter = 'All',sort: string = 'DESC', limit: number = 100, page: number = 1) {
    return this.http.get(this.livestreamURL + `?limit=${limit}&page=${page}&sort=${sort}&filter=${filter}`);
  }
  getSingleLiveStream(id: number) {
    return this.http.get(this.livestreamURL + `/single/${id}`)
  }
  getAtiveLiveStream() {
    return this.http.get(this.livestreamURL + '/active')
  }
  updateLiveStatus(id: any, url?: string) {
    return this.http.post(`${this.livestreamURL}/live-livestream`, { id: id, url })
  }
  endLivestream(id: any) {
    return this.http.post(`${this.livestreamURL}/end-livestream`, { id })
  }
  invoiceLivestream(id: any) {
    return this.http.post(`${this.livestreamURL}/invoice-livestream`, { id })
  }
  archiveLivestream(id: any) {
    return this.http.post(`${this.livestreamURL}/archive-livestream`, { id })
  }
  getVendorList() {
    return this.http.get(this.livestreamURL + '/get-vendors')
  }
  getStaticsData(id: number) {
    return this.http.post(this.livestreamURL + '/get-stats', { id })
  }
  getLiveStream_PDF(id: any) {
    return this.http.get(this.livestreamURL + '/print/' + id)
  }
  getSocialPlatform(){
    return this.http.get(this.livestreamURL + '/social-platform')

  }
  getHostByLivestreamId(id:any){
    return this.http.get(this.livestreamURL + '/get-host-by-livestream/' + id)
  }
  getPlatformByLivestreamId(id:any){
    return this.http.get(this.livestreamURL + '/get-platform-by-livestream/' + id)
  }
  archiveToInvoiceLivestream(id:any){
    return this.http.post(`${this.livestreamURL}/archive-to-invoice-livestream`, { id })
  }
}
