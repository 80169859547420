export const environment = {
    production: true,
    facebookAppId:'246640534966013',
    FbVersion: 'v17.0',
    pageId:'106584975435576',
    project:'gemmonkey',
    
    /* LIVE SERVER URLS ACCORDING TO DOMAIN */
    
    // serverUrl:'https://api.thetucsongemshow.live',  // for the tucsongemshow live server url
    // serverUrl:'https://apistaging.thetucsongemshow.live',    // for staging server url
    serverUrl:'https://gemmonkeyapi.thetucsongemshow.live/',    // for gemmonkey server url
  
    // serverUrl: 'https://sunwestsilver.thetucsongemshow.live/', // For Sunwest silver server url
};