import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public alertObserver:Subject<any>=new Subject()
  constructor() { }
  showAlert(alertMessage=''){
    this.alertObserver.next(alertMessage)
  }

}
