import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }
  getFaceBookUserId() {
    return localStorage.getItem('FACEBOOK_ACCESS_TOKEN') || ''
  }
  saveAccessToken(val: string = '') {
    localStorage.setItem('FACEBOOK_ACCESS_TOKEN', val)
  }
  removeAccount() {
    localStorage.removeItem('FACEBOOK_ACCESS_TOKEN')
  }
  saveToken(token: string, data: any) {
    localStorage.setItem('TOKEN', token)
    localStorage.setItem('USER_DATA', JSON.stringify(data))
  }
  saveEditData(data:any){
    localStorage.setItem('USER_DATA', JSON.stringify(data));

  }
}
