import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { ToastrService } from 'src/app/core/toastr/toastr.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  isSubmitted: boolean = false;
  title!: string;
  constructor(private authService: AuthService, private local_storage: LocalstorageService, private router: Router,
     private toast: ToastrService,public  location:Location) { }

  ngOnInit(): void {
    this.authService.featureAllow().subscribe((res:any)=>{
      if(res.data.register){
        return
      }else{
    this.router.navigateByUrl('/login')

      }
    })
  }
  ClientRegister(f: NgForm) {
    this.isSubmitted = true;
    if (f.valid) {

      // if (!(f.value.password === f.value.Cpassword)) {
      //   this.toast.showWarn("Password and Confirm Password must be match")
      //   return ;
      // }
      const data = {
        first_name: f.value.first_name,
        email: f.value.email,
        password: f.value.password,
        // Cpassword: f.value.Cpassword,
        // mobile: f.value.mobile,
        agreement: 1,
        postal_code:f.value.postal_code
      }
      this.authService.registration(data).subscribe((response: any) => {
        
        if (response.success) {

          this.toast.showSuccess(response.message)

          return this.router.navigateByUrl('/login')

          // return response.data.roles_id == 2 ? this.router.navigateByUrl('/user') : this.router.navigateByUrl('/admin')
        }

        else {
          this.toast.showWarn("WARNING", response.message);
          return;
        }
      })
    }

    return

  }

  
  navigateToHome(){
    this.router.navigateByUrl('/login')
  }

  refresh(){
    window.location.reload();
  }
}
