
import { environment } from 'src/environments/environment';
import { FacebookServiceService } from '../services/facebook-service.service';

export function appInitializer(accountService: FacebookServiceService) {
    return () => new Promise((resolve: any) => {
        FB.init({
            appId:  environment.facebookAppId||'2412041408936539',
            cookie: true,
            autoLogAppEvents: true,
            xfbml: true,
            version: environment.FbVersion || 'v16.0'
            
        });

        FB.getLoginStatus(({ authResponse }: any) => {
            if (authResponse) {
                accountService.apiAuthenticate(authResponse.accessToken)
                    .subscribe()
                    .add(resolve);
            } else {
                resolve({});
            }
        });
        (function (d, s, id) {
        }());
    });
}
