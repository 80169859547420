import { LocalstorageService } from './core/services/localstorage.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { environment } from 'src/environments/environment';
import { DomainSettingService } from './core/services/domain-setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'livestream';
  first: boolean = false;
  logo:any;
  constructor(private router: Router, private localStorageService: LocalstorageService,
    private domainSetting:DomainSettingService) {
   
  }

  ngOnInit(): void {
this.domainSetting.getBackground_logo().subscribe((logo:any)=>{
this.logo= logo;
})
    var chatbox = document.getElementById('fb-customer-chat') as HTMLElement;
    if (chatbox) {
      chatbox.setAttribute("page_id", environment.pageId);
      chatbox.setAttribute("attribution", "biz_inbox");
  } else {
      // console.error('Chatbox element not found');  
  }
  this.navigate()
  
  }
  applyChanges(designConfigration: any) {
    let val = designConfigration.background

    document.body.className = val + " " + (designConfigration.theme == 'light' ? 'Light modal-open' : ' modal-open')

  }
  navigate(): any {

  }
}
