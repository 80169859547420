import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PdfRecieptService {
  public orderObserber:Subject<any>=new Subject()
  data :any
  constructor() {
  }

  printReceipt(data:any){

    setTimeout(()=>{
      window.print()
    },200)
    this.data = {...this.data,...data}
    this.orderObserber.next(this.data)
  }
  

  
}
