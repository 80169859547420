<div id="toast-container" *ngIf="show" class="toast-top-right">
    <div class="toast" [ngClass]="{
      'toast-success': type == 'success',
      'toast-error': type == 'error',
      'toast-warning': type == 'warn'
    }" aria-live="polite" style="display: block">
        <button type="button" class="toast-close-button" (click)="show = false" role="button">
      ×
    </button>
        <div class="toast-title">{{ title }}</div>
        <div class="toast-message">{{ message }}</div>
    </div>
</div>