import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateFormat' })
export class DateFormat implements PipeTransform {
  transform(value: string): String {
    const Month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
    var d = new Date(value);

    return d.getUTCDate() + " " + Month[d.getUTCMonth()] + " " + d.getUTCFullYear()
  }
}
