import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  public toastrConfig: any = {
    show: false, delay: 2500, type: 'warn', title: '', message: ''
  }
  public toastrObserver: Subject<any> = new Subject();
  constructor() {

  }

  showToastr(title: string = '', message: string = '', type = 'warn'): void {
    this.toastrConfig.show = true;
    this.toastrConfig.title = title;
    this.toastrConfig.message = message
    this.toastrConfig.type = type || 'warn'
    this.toastrObserver.next(this.toastrConfig)
  }
  public getToastrStutus() {
    return this.toastrObserver.asObservable();
  }
  showError(title = 'ERROR', message?: string) {
    this.showToastr(title, message, 'error')
  }
  showSuccess(title = 'SUCCESS', message?: string) {
    this.showToastr(title, message, 'success')
  }
  showWarn(title = 'WARNING', message?: string) {
    this.showToastr(title, message, 'warn')
  }

}
