<div class="non-print">
    <router-outlet></router-outlet>

    <app-toastr></app-toastr>
    <app-loader></app-loader>
    <app-alert></app-alert>
    <!-- <img class="background-logo" src="assets/images/The-Tucson-Gem-Show-Logo-Final-grayscale.png" /> -->
    <img class="background-logo" [src]="logo" />

    
</div>

<app-pdf-receipt tabindex="-1" class="print"></app-pdf-receipt>