import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { LocalstorageService } from '../../core/services/localstorage.service';
import { ToastrService } from '../../core/toastr/toastr.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  password: string = '';
  confirmPassword: string = '';
  token:any;


 
  constructor(
     private toast: ToastrService,
    private router: Router, private authService: AuthService, private local_storage: LocalstorageService, private activateRoute :ActivatedRoute) { }

  ngOnInit(): void {
this.token = this.activateRoute.snapshot.queryParams['token'];
   
    
  } 
  resetPassword() {
    if (this.password !== this.confirmPassword) {
      // Handle password mismatch error
      alert('Passwords do not match!');
      return;
    }
    const data = {
      password:this.password,
      token : this.token
    }
this.authService.restPassword(data).subscribe((res:any)=>{
if(res.success){
  this.toast.showSuccess(res.message);
  this.router.navigateByUrl('/login');

}else{
  this.toast.showSuccess(res.message);
}

})
    // Reset password logic here (e.g., send password to server)
  }
}
