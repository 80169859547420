<div class="container-fluid">
  <div class="row justify-content-center align-items-center vh-100">
    <div class="col-md-6 col-lg-4">
      <div class="reset-password-form">
        <h2>Reset Password</h2>
        <form (ngSubmit)="resetPassword()">
          <div class="form-group">
            <label for="password">New Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter new password"
              name="password"
              [(ngModel)]="password"
            />
          </div>
          <div class="form-group">
            <label for="confirm-password">Confirm New Password</label>
            <input
              type="password"
              id="confirm-password"
              name="confirm-password"
              placeholder="Confirm new password"
              name="confirmPassword"  
              [(ngModel)]="confirmPassword"
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  </div>
</div>
