import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public productURL: string = environment.serverUrl + '/product'
  constructor(private http: HttpClient) { }
  getList() {

    return this.http.get(`${this.productURL}/`)
  }
  addProduct(data: any) {
    return this.http.post(`${this.productURL}/`, data)
  }
  editProduct(data: any) {
    return this.http.put(`${this.productURL}/`, data)
  }
  getProduct(id: number) {
    return this.http.get(`${this.productURL}/${id}`)
  }
}
