<!-- replace login  -->

<div class="BodyBox">
  <div class="TitleBox">
    <span class="TitleBox__Text Header_Title"
      ><img [src]="logoUrl" alt="logo" height="100px" width="100px" /> &nbsp;{{
        title
      }}
      : Live</span
    >
    <!-- <span class="TitleBox__Text">Live</span> -->
  </div>
  <div class="ToolBarBox">
    <div id="leftButtonBank" class="ToolBarButtonBank reverse">
      <!-- <div class="header-element"> <img src="assets/img/png/key.png" /> </div> -->
    </div>
    <div style="flex-grow: 2">&nbsp;</div>
    <div id="navButtonBank" class="ToolBarButtonBank" style="display: none">
      &nbsp;
    </div>
  </div>
  <div class="ResponsiveContentBox">
    <div class="ResponsiveHalfBox cropped leftBox login-text-opacity">
      <div class="StandardText RightPadded">
        <!-- <strong class="titleLoginStyle">{{ title }} : Live</strong> is your
        online destination for Live Streaming Gem Shows. <br /><br />
        To set up your cart and claim items during our Live Shows, press the
        blue Facebook button and say Yes to use the {{ title }}.Live site.
        <br /><br /> -->

        <strong class="titleLoginStyle">{{ title }} : Live</strong> is your
        online destination for Live Streaming Gem Shows. <br /><br />
        To set up your cart and claim items during our Live Shows, press the
        blue Facebook button and say Yes to use {{ title }}.Live site.
        Alternatively, you can also click the purple register button, if don't
        want to use Facebook 1-click login, and register yourself using a
        username and password. <br /><br />
      </div>
    </div>
    <div class="ResponsiveHalfBox centerstart clear rightBox">
      <div class="ButtonGroup centered">
        
        <div class="mt-3 d-flex justify-content-center border-bottom">
          <app-facebook-login></app-facebook-login>
        </div>

        <div class="mt-3 d-flex justify-content-center text-center ">
          <p class="titleLoginStyle w-75">I don't want to use Facebook 1-click login, hit the purple register button below</p>
        </div>

        <div class="PageButtonSplitter">
          <form #f="ngForm" (submit)="logInAdmin(f)">
            <div class="form-group">
              <input
                type="text"
                class="p-3 form-control"
                id="exampleInputEmail1"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                ngModel
                name="email"
                required="true"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
              <div *ngIf="isSubmitted && f.controls['email'].errors">
                <small
                  *ngIf="isSubmitted && f.controls['email'].errors['required']"
                  class="form-text text-danger"
                  style="color: red !important"
                  >Email is required</small
                >
                <small
                  *ngIf="isSubmitted && f.controls['email'].errors['pattern']"
                  class="form-text text-danger"
                  style="color: red !important"
                  >Email is not valid</small
                >
              </div>
            </div>
            <div class="form-group">
              <input
                type="password"
                class="p-3 form-control"
                id="exampleInputPassword1"
                ngModel
                name="password"
                required="true"
                placeholder="Enter Password"
              />
              <div *ngIf="isSubmitted && f.controls['password'].errors">
                <small
                  *ngIf="
                    isSubmitted && f.controls['password'].errors['required']
                  "
                  class="form-text text-danger"
                  style="color: red !important"
                  >Password is required</small
                >
                <!-- <small *ngIf="isSubmitted && f.controls['password'].errors['email']" class="form-text text-danger">Email is required</small> -->
              </div>
            </div>
          
           <div class="titleLoginStyle">
            <a
            data-id="sign-in-form__forgot-password"
            class="forget-color"
            href="forget-password"
           
            >Forgot password?</a>
           </div>
            
            

           

            <!-- <a class="register" *ngIf="allowed" (click)="goto()"
              >Register Here ?</a
            > -->

            <div class="outer-btn-container">
              <button
              type="submit"
              class="btn btn-primary text-white"
              style="color: white !important;
              background-color: #ab8cff !important;"
            >
              Login
            </button>

            <button
            *ngIf="allowed"
            (click)="goto()"
            class="btn btn-primary text-white"
            style="
              color: white !important;
              background-color: #ab8cff !important;
            "
          >
            Register
          </button>
        </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
