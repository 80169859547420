import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { LocalstorageService } from '../../core/services/localstorage.service';
import { ToastrService } from '../../core/toastr/toastr.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  email: string = ''; 
 
  constructor(
     private toast: ToastrService,
    private router: Router, private authService: AuthService, private local_storage: LocalstorageService) { }

  ngOnInit(): void {
    
  }
  submitForm(f:NgForm){
    this.authService.forgetPassword({email:this.email}).subscribe((res:any)=>{
if(res.success){
  this.toast.showSuccess(res.message);
f.reset()
}else{
  this.toast.showSuccess(res.message);
}


    })

  }
}
