import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { CommonPagesModule } from '../layout/common/common-pages.module';
import { FacebookServiceService } from '../core/services/facebook-service.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedModulesModule } from '../core/shared-modules.module';
import { FormsModule } from '@angular/forms';
import { appInitializer } from '../core/intilizer/fb-initlizer';
import { FacebookLoginComponent } from './facebook-login/facebook-login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';



@NgModule({
  declarations: [
    FacebookLoginComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModulesModule
  ],
  providers:[FacebookServiceService],
  exports:[FacebookLoginComponent]
})
export class LoginModule { }
