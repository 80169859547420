import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { DomainSettingService } from 'src/app/core/services/domain-setting.service';
import { FacebookServiceService } from 'src/app/core/services/facebook-service.service';


@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  public username: string = '';
  public userData: any;
  public activeURL: any;
  public headerTiles: string = 'TheTucsonGemShow';
  public logoUrl: any;
  public headerTitle: any;
  logoSubscription: any;
  headerSubscription: any;
  title: any;



  constructor(private facebookService: FacebookServiceService, private route: ActivatedRoute,
    public router: Router, private authSerivice: AuthService, public location: Location,
    private domainSettingsService: DomainSettingService

  ) {
    this.logoSubscription = this.domainSettingsService.getLogoObservable().subscribe((logo) => {
      // this.logoUrl = `/assets/images/${logo}`;
      this.logoUrl = `${logo}`;

    });

    this.headerSubscription = this.domainSettingsService.getHeaderTitleObservable().subscribe((title) => {
      this.title = title;
    });



    this.router.events.subscribe((eve: any) => {
      if (eve instanceof NavigationEnd) {

        let url: string = eve.urlAfterRedirects

        let c = url.split('/')
        this.activeURL = c.splice(1)


        this.route.firstChild?.data.subscribe((res: any) => {
          if (res.title) {

            this.headerTitle = (res.title ? (this.title + `${res.title}`) : this.title)



          }
        })
      }
    })

  }

  ngOnInit(): void {


    let userData: any = JSON.parse(localStorage.getItem('USER_DATA') || '')
    this.userData = userData
    this.username = userData.first_name && userData.last_name ? `${userData.first_name} ${userData.last_name}` : ''
    // this.router.events.subscribe((val:any)=>{
    //   if(val instanceof NavigationEnd){
    //     let c = val.url.split('/')
    //     this.activeURL = c.splice(1)
    //   }
    // })


  }


  refresh() {
    window.location.reload();
  }
  async logout() {
    this.authSerivice.logout()

    this.router.navigateByUrl('/login')

    await this.facebookService.logout()
  }

  navigateToHome() {
    this.router.navigateByUrl('/user')
  }
  ngOnDestroy() {
    this.logoSubscription.unsubscribe();
    this.headerSubscription.unsubscribe();
  }

}
