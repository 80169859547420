<div class="BodyBox">
    <div class="TitleBox">
        <span class="TitleBox__Text Header_Title"><img src="assets/images/logo-round.png" alt="logo" height="100px"
                width="100px" />
            &nbsp;{{ title }} : Live</span>
        <!-- <span class="TitleBox__Text">Live</span> -->
    </div>
    <div class="ToolBarBox">
        <div id="leftButtonBank" class="ToolBarButtonBank reverse">
            <!-- <div class="header-element"> <img src="assets/img/png/key.png" /> </div> -->
            <div id="leftButtonBank" class="ToolBarButtonBank reverse">
                <!-- <div  class="ToolBarButton reverse" (click)="location.back()" title="Back"> -->
                <div  class="ToolBarButton reverse" (click)="this.location.back()" title="Back">
                    <img src="assets/img/icons/back.svg" alt="Back" class="ToolBarButton__Icon">
                </div>
              
            </div>
        </div>
        <div style="flex-grow: 2">&nbsp;</div>
        <div id="navButtonBank" class="ToolBarButtonBank" style="display: none">
            &nbsp;
        </div>
    </div>
    <div class="ResponsiveContentBox">
        <div class="ResponsiveHalfBox cropped">
            <div class="StandardText RightPadded">
                <strong>{{ title }} : Live</strong> is your online destination for Live Streaming Gem Shows.
                <br /><br /> To set up your cart and claim items during our Live Shows, press the blue Facebook button
                and say Yes to use the {{ title }}.Live
                site.
                <br /><br />
            </div>
        </div>
        <div class="ResponsiveHalfBox centerstart clear">
            <div class="ButtonGroup centered">
                <div class="PageButtonSplitter">
                    <form #f="ngForm" (submit)="ClientRegister(f)">
                        <div class="form-group">
                            <input type="text" class=" form-control" id="first_name"
                             ngModel name="first_name"
                                required="true" aria-describedby="first_name" placeholder="Enter Name" />
                            <div *ngIf="isSubmitted && f.controls['first_name'].errors">
                                <small *ngIf="isSubmitted && f.controls['first_name'].errors['required']"
                                    class="form-text text-danger" style="color: red !important">Name is
                                    required</small>
                            
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class=" form-control" id="exampleInputEmail1"
                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" ngModel name="email"
                                required="true" aria-describedby="emailHelp" placeholder="Enter Email" />
                            <div *ngIf="isSubmitted && f.controls['email'].errors">
                                <small *ngIf="isSubmitted && f.controls['email'].errors['required']"
                                    class="form-text text-danger" style="color: red !important">Email is
                                    required</small>
                                <small *ngIf="isSubmitted && f.controls['email'].errors['pattern']"
                                    class="form-text text-danger" style="color: red !important">Email is not
                                    valid</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="password" class=" form-control" id="exampleInputPassword1" ngModel
                                name="password" minlength="4" required="true" placeholder="Enter Password" />
                            <div *ngIf="isSubmitted && f.controls['password'].errors">
                                <small *ngIf="
                    isSubmitted && f.controls['password'].errors['required']
                  " class="form-text text-danger" style="color: red !important">Password is required</small>
                                <small *ngIf="isSubmitted && f.controls['password']['errors']['minlength']"  class="form-text text-danger" style="color: red !important">Password length more than 4 character</small>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <input type="password" min="4" class=" form-control" id="exampleInputPassword1" ngModel
                                name="Cpassword" required="true" placeholder="Enter Confirm Password" />
                            <div *ngIf="isSubmitted && f.controls['Cpassword'].errors">
                                <small *ngIf="
                                  isSubmitted && f.controls['Cpassword'].errors['required']
                                " class="form-text text-danger" style="color: red !important">Confirm Password is
                                    required</small>
                                <small *ngIf="isSubmitted && f.controls['Cpassword']['errors']['minlength']"  class="form-text text-danger" style="color: red !important">Password length more than 4 character</small>

                            </div>
                        </div> -->

                        <!-- <div class="form-group">
                            <input type="number" class=" form-control" id="mobile" ngModel
                                name="mobile"  placeholder="Enter Mobile Number" />
                              
                        </div> -->

                        <div class="form-group">
                            <input type="number " minlength="5" maxlength="5" class=" form-control" id="postal_code" ngModel
                                name="postal_code" required="true" placeholder="Enter Zip Code" />
                            <div *ngIf="isSubmitted && f.controls['postal_code'].errors">
                                <small *ngIf="
                                  isSubmitted && f.controls['postal_code'].errors['required']
                                " class="form-text text-danger" style="color: red !important">ZIP code  is
                                    required</small>
                                <!-- <small *ngIf="isSubmitted && f.controls['postal_code']['errors']['maxlength']"  class="form-text text-danger" style="color: red !important">ZIP code length</small> -->

                            </div>
                        </div>

                        <div class="form-group">
                                <input class="form-check-input" class="p-1" type="checkbox" required="true" name="agreement" value="" id="agreement" placeholder="Enter Phone Number"  ngModel>
                                <label class="form-check-label labelColor" for="agreement">
                                    I read and accepted <a href="/assets/privacy-policy.txt" class="labelColor"> privacy policy</a>
                                </label>
                                <div *ngIf="isSubmitted && f.controls['agreement'].errors">
                                    <small *ngIf="
                                      isSubmitted && f.controls['agreement'].errors['required']
                                    " class="form-text text-danger" style="color: red !important">Agreement is
                                        required</small>
                                </div>
                        </div>

                    







                        <button type="submit" class="btn btn-primary text-white" style="color: white !important">
                            Register
                        </button>
                        <br />
                        <br />

                        <!-- <a routerLink="/login">Login Via Social Platform</a> -->
                        <app-facebook-login></app-facebook-login>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>