import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { rmSync } from 'fs';
import { AuthService } from '../core/services/auth.service';
import { DomainSettingService } from '../core/services/domain-setting.service';
import { ToastrService } from '../core/toastr/toastr.service';

@Component({
  selector: 'app-gemmonkey',
  templateUrl: './gemmonkey.component.html',
  styleUrls: ['./gemmonkey.component.scss']
})
export class GemmonkeyComponent implements OnInit {

  constructor(private authService:AuthService, private toster: ToastrService, private domainSetting:DomainSettingService, private route :Router) { }

  ngOnInit(): void {
  }

  saveproductDetails(f:any){
this.authService.scheduleForm(f.value).subscribe((res:any)=>{
  if(res.success){
    this.toster.showSuccess(res.message);
  }else{
    this.toster.showError(res.message);

  }
  
})

  }

  loginPortal(){
    this.domainSetting.getPortalLoginUrl().subscribe((url:any)=>{
      
      this.route.navigateByUrl(url)
    })
    
    
  }
 
}
