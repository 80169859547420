import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { FacebookServiceService } from 'src/app/core/services/facebook-service.service';
import { environment } from 'src/environments/environment';
import { DomainSettingService } from 'src/app/core/services/domain-setting.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public username: string = '';
  public userData: any;
  public activeURL: any;
  headerTiles!: string;
  public logoUrl: any;
  public headerTitle: any;
  logoSubscription: any;
  headerSubscription: any;
  title: any;
  is_ZIPCODE_Filled: boolean = true;


  constructor(private facebookService: FacebookServiceService, private route: ActivatedRoute, public router: Router,
    private authSerivice: AuthService, public location: Location,
    private domainSettingsService: DomainSettingService
  ) {

  }

  ngOnInit(): void {
    this.logoSubscription = this.domainSettingsService.getLogoObservable().subscribe((logo) => {
      // this.logoUrl = `/assets/images/${logo}`;
      this.logoUrl = `${logo}`;

    });
    this.headerSubscription = this.domainSettingsService.getHeaderTitleObservable().subscribe((title) => {
      this.title = title;

    });
    this.route.firstChild?.data.subscribe((res: any) => {

      if (res.title) {
        this.headerTitle = (res.title ? (this.title + `${res.title}`) : this.title);
      }
    })

    let userData: any = JSON.parse(localStorage.getItem('USER_DATA') || '')
    this.userData = userData
    this.username = userData.first_name && userData.last_name ? `${userData.first_name} ${userData.last_name}` : ''
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        let c = val.url.split('/')
        this.activeURL = c.splice(1)

      }

    })
    this.authSerivice.checkZIPCODE.subscribe((res: any) => {
      this.is_ZIPCODE_Filled = res;

    })
  }

  logout() {
    this.authSerivice.logout()
    this.facebookService.logout()
    this.router.navigateByUrl('login')
  }

  ngOnDestroy() {
    this.logoSubscription.unsubscribe();
    this.headerSubscription.unsubscribe();
  }


}
