import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @ViewChild('content', { static: true }) content!: NgbModalRef;
  alertMessage: string = '';

  constructor(private alertService: AlertService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.alertService.alertObserver.asObservable().subscribe((message: string) => {
      if (message) {
        this.openAlertModal(message)
      }
    })
  }
  openAlertModal(message: string) {
    let modal = this.modalService.open(this.content, { animation: true, centered: true }).result.then((result: any) => {
      this.alertMessage = ''
      this.modalService.dismissAll()
    }, (reason: any) => {
      this.alertMessage = ''
      this.modalService.dismissAll()
    })
    this.alertMessage = message
  }

}
