import { Component, OnInit } from '@angular/core';
import { ToastrService } from './toastr.service';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
//export interface enum ToastrtTYPE{ERROR='ERROR',SUCCESS='SUCCESS',WARN='WARN'};
export class ToastrComponent implements OnInit {
  public show = false;
  public delay = 2500;
  public type = 'warn';
  public title: string = '';
  public message: string = '';
  private service: any;
  constructor(private toatrService: ToastrService) {

  }

  ngOnInit(): void {
    this.service = this.toatrService.getToastrStutus().subscribe((res: any) => {
      this.message = res.message
      this.title = res.title || 'Error'
      this.type = res.type || 'warn'
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, this.delay);
    })
  }
  ngOnDestroy(): void {
    this.service.unsubscribe()
  }

}
