import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { RegisterComponent } from './register/register.component';
import { GemmonkeyComponent } from './gemmonkey/gemmonkey.component';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';

// const title = environment.projectTitle
const condiationRouting = ():Routes => {
 
  if (environment.project == 'gemmonkey') {
    return [{
      path: '',
      component: GemmonkeyComponent
    },
    {
      path: '',
      redirectTo: '',
      pathMatch: 'full'
    }]
  }
  return [
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
    }

  ]

}

const conditionDashboard = () =>{
  const user = JSON.parse(localStorage.getItem('USER_DATA') || '{}');
  if(user.roles_id === 5){
    return ':Owner Dashboard'
  }else if( user.roles_id === 1){
    return ':Admin Dashboard'
  }else{
    return ':Employee Dashboard'

  }
 
}
const routes: Routes = [
  {
    path: '', component: AppComponent,
    children: [
      ...(condiationRouting())
      ,
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      // {
      //   path: 'login-user',
      //   loadChildren: () => import('./admin-login/admin-login.module').then(m => m.AdminLoginModule)
      // },
      {
        path: 'user',
        loadChildren: () => import('./layout/user/user.module').then(m => m.UserModule),
        // data: { title: title + ' :User Dashboard' }
        data: { title: ' :User Dashboard' }


      },
      {
        path: 'admin',
        loadChildren: () => import('./layout/admin/admin.module').then(m => m.AdminModule),
        // data: { title: title + ' :Admin Dashboard' }
        data: { title: conditionDashboard()  }

      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'forget-password',
        component: ForgetPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: '**',
        redirectTo: 'user',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
