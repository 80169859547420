


import { Directive, ElementRef, HostListener } from '@angular/core';

declare var webkitSpeechRecognition: any;

interface SpeechRecognitionEvent extends Event {
  readonly resultIndex: number;
  readonly results: SpeechRecognitionResultList;
}

interface SpeechRecognitionResultList {
  readonly length: number;
  item(index: number): SpeechRecognitionResult;
}

interface SpeechRecognitionResult {
  readonly length: number;
  item(index: number): SpeechRecognitionAlternative;
}

interface SpeechRecognitionAlternative {
  readonly transcript: string;
  readonly confidence: number;
}

@Directive({
  selector: '[appSpeechRecognition]'
})
export class SpeechRecognitionDirective {

  private recognition: any;
  private isRecognizing: boolean = false;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.lang = 'en-US';

    const image = document.createElement('div');
    image.innerHTML = '<img class="mic-icon" src="../../../../assets/img/icons/micIcon.png"></img>';
    image.addEventListener('click', () => this.onClick());
    this.elementRef.nativeElement.insertAdjacentElement('afterend', image);

  }

  onClick() {
    const micIcon = this.elementRef.nativeElement.nextElementSibling!;

    if (this.isRecognizing) {
      this.recognition.stop();
      this.isRecognizing = false;
      micIcon.getElementsByTagName('img')[0].classList.remove('active');

    } else {
      this.recognition.start();
      this.isRecognizing = true;
      micIcon.getElementsByTagName('img')[0].classList.add('active');
    }

    this.recognition.onresult = (event: SpeechRecognitionEvent) => {

      const results: SpeechRecognitionResultList = event.results;
      const transcript = results.item(0)?.item(0)?.transcript;
      this.elementRef.nativeElement.value += transcript;
    };


    this.recognition.onend = () => {
      this.isRecognizing = false;
      // micIcon.classList.remove('active');
      micIcon.getElementsByTagName('img')[0].className = 'mic-icon';

    };
  }
}



