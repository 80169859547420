<ng-template #content let-modal>
    <div id="doubleDialog" class="" style="display: flex">
        <div class="DialogBox double">
            <div class="DialogTitle">
                <div id="doubleDialogTitle">Alert</div>
                <div>
                    <span class="DialogBoxClose" (click)="modal.dismiss('cross clicked')">×</span
          >
        </div>
      </div>
      <div id="doubleDialogContent" class="DialogContent">
        <div class="DialogForm">
          <div class="DialogPanel medium margined">
            <div class="DialogDataBox">
              {{ alertMessage }}
            </div>
          </div>
        </div>
        <br /><br />
        <button type="submit" #submitBtn style="display: none"></button>
        <div class="DialogButtonBar">
          <div
            class="DialogButton"
            (click)="modal.close('')"
            title="Close Dialog"
          >
            Close
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>